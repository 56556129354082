import './_featured-auctions-block.scss'

const featuredAuctionsBlock = function() {
    const blocks = document.querySelectorAll('section.featured-auctions-block');

    for (const block of blocks) {
        let tabs = block.querySelectorAll('.nav li');
        tabs.forEach(function(tab) {
            tab.addEventListener('click', onTabClick, false);
        })
    }
    function onTabClick(event) {
        event.preventDefault()
        let tabs = event.target.closest('.tabs-wrapper');
        let activeTabs = tabs.querySelectorAll('.active');
        let linkDesktop = tabs.querySelector('.cta.destroy-mobile a');
        let linkMobile = tabs.querySelector('.cta.show-mobile a');

        activeTabs.forEach(function(tab) {
            tab.className = tab.className.replace('active', '');
        });

        event.target.parentElement.className += ' active';
        if(linkDesktop && linkMobile) {
            linkDesktop.setAttribute("href", event.target.parentElement.getAttribute('data-link'));
            linkMobile.setAttribute("href", event.target.parentElement.getAttribute('data-link'));
        }
        tabs.querySelector('#' + event.target.href.split('#')[1]).className += ' active';
    }
};

document.addEventListener('DOMContentLoaded', function(){
    featuredAuctionsBlock()
}, false);

window.addEventListener('acf/loaded/featured-auctions-block', function (e) {
    featuredAuctionsBlock()
});